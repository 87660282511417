import getBrandingData from 'hooks/useInternalBranding';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { BrandingData } from 'constants/home';

const InternalBrandingContext = createContext<BrandingData | null>(null);
interface Props {
  children: ReactNode;
  initialBranding: BrandingData;
}

export const InternalBrandingProvider = ({
  children,
  initialBranding,
}: Props) => {
  const [internalBrandingData, setInternalBrandingData] = useState(
    initialBranding
  );

  useEffect(() => {
    if (!initialBranding) {
      getBrandingData().then(setInternalBrandingData);
    }
  }, [initialBranding]);

  return (
    <InternalBrandingContext.Provider value={internalBrandingData}>
      {children}
    </InternalBrandingContext.Provider>
  );
};

export const useInternalBranding = () => {
  return useContext(InternalBrandingContext);
};
