import apiFetch from 'api/core/apiFetch';
import { checkStatus, handleError } from 'utils/api';
import { getAuthTokenAsync } from 'utils/authToken';
import { SystemSetting } from 'interfaces/systemSetting';

interface SystemSettings {
  systemSettings: SystemSetting[] | null;
}

const getSystemSettingsAsync = async (): Promise<SystemSettings> => {
  const url = `/api/v1/system_settings`;

  try {
    const authToken = await getAuthTokenAsync();
    const response = await apiFetch(authToken, url, {
      method: 'GET',
      headers: { 'Accept-Language': 'en' },
    });

    await checkStatus(response);

    const parsedResponse = await response.json();

    const { system_settings } = parsedResponse;

    return { systemSettings: system_settings };
  } catch (error) {
    handleError('unexpected status - v1 get system settings', error);
    return { systemSettings: null };
  }
};

export default getSystemSettingsAsync;
