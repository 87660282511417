import React, { useEffect } from 'react';
import App from 'next/app';
import { NextComponentType, NextPageContext } from 'next';
import Head from 'next/head';
import Router from 'next/router';
import * as Sentry from '@sentry/node';
import { GridThemeProvider, BaseCSS } from 'styled-bootstrap-grid';
import { gridTheme, theme } from 'mdlkit';
import { InternalBrandingProvider } from 'components/app/InternalBrandingContext';
import { useComponentAsRoute } from '../hooks';
import {
  BrandingProvider,
  AffiliationThemeProvider,
  IntlProviderWrapper,
  CustomizableProvider,
  PersistParamsProvider,
} from '../components/app';
import GlobalStyle from '../core/GlobalStyle';
import ErrorBoundary from '../components/ErrorBoundary';
import { ToastContainer, ToastClose } from '../components/shared';
import { pageview } from '../lib/analytics';
import getAffiliationBrandingAsync from '../api/branding/getAffiliationBrandingAsync';
import '@reach/dialog/styles.css';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NEXT_ENVIRONMENT,
});

interface AppProps {
  Component: NextComponentType<NextPageContext, any, {}>;
  pageProps: any;
  internalBrandingData: any;
}

const MyApp = ({
  Component,
  pageProps: { branding, ...rest },
  internalBrandingData,
}: AppProps) => {
  const { getUrlForComponentRoute } = useComponentAsRoute();

  const handleRouteChange = (url: string) => {
    const urlFromComponentRoute = getUrlForComponentRoute(url);

    window.createPageLoadEvent(urlFromComponentRoute ?? url);
    pageview(url);
  };

  useEffect(() => {
    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, height=device-height"
        />
      </Head>
      <PersistParamsProvider>
        <CustomizableProvider>
          <InternalBrandingProvider initialBranding={internalBrandingData}>
            <BrandingProvider branding={branding}>
              <AffiliationThemeProvider
                brandingColors={branding?.colors}
                defaultTheme={theme}
              >
                <GridThemeProvider gridTheme={gridTheme}>
                  <IntlProviderWrapper>
                    <BaseCSS />
                    <GlobalStyle />
                    <ErrorBoundary>
                      <ToastContainer
                        autoClose={false}
                        hideProgressBar
                        closeOnClick={false}
                        closeButton={<ToastClose />}
                        draggable={false}
                      />
                      <Component {...rest} />
                    </ErrorBoundary>
                  </IntlProviderWrapper>
                </GridThemeProvider>
              </AffiliationThemeProvider>
            </BrandingProvider>
          </InternalBrandingProvider>
        </CustomizableProvider>
      </PersistParamsProvider>
    </>
  );
};

// Custom app disables automatic static optimization. See https://nextjs.org/docs/advanced-features/custom-app
MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  const { affiliation }: { affiliation?: string } = appContext.router.query;

  if (typeof affiliation !== 'undefined') {
    const { locale } = appContext.router;
    const { branding } = await getAffiliationBrandingAsync(affiliation, locale);

    if (branding !== null) {
      const { pageProps: rest } = appProps;
      const pageProps = {
        ...rest,
        branding,
      };

      return { ...appProps, pageProps };
    }
  }
  return { ...appProps };
};

export default MyApp;
