import getSystemSettingsAsync from 'api/systemSettings/getSystemSettings';
import { BRANDING_DATA, BrandingData } from 'constants/home';

const patientEvernorthBrandingEnabled = (systemSettings): any => {
  if (!Array.isArray(systemSettings)) return 'false';

  const setting = systemSettings.find(
    (systemSetting) =>
      systemSetting.name === 'patient_evernorth_branding_enabled'
  );

  if (!setting || setting.value === undefined || setting.value === null)
    return 'false';

  return setting.value;
};

const brandingData = (isEnabled): BrandingData =>
  isEnabled === 'true' ? BRANDING_DATA.evernorth : BRANDING_DATA.mdlive;

const getBrandingData = async (): Promise<BrandingData> => {
  const { systemSettings } = await getSystemSettingsAsync();
  const internalBrandingEnabled = patientEvernorthBrandingEnabled(
    systemSettings
  );

  return brandingData(internalBrandingEnabled);
};

export default getBrandingData;
